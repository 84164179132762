import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "text-field",
      "style": {
        "position": "relative"
      }
    }}>{`Text Field`}</h1>
    <p>{`Text field enables the user to interact with input data. They are normally found within a form but can also be part of a modal, search or card.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Use placeholders to provide hints or examples of what to enter.`}</li>
      <li parentName="ul">{`Required fields must be signed with a red asterisk. If all fields are required there is no need to display an asterisk in all of them, but the obligatoriness must be indicated at the top of the page.`}</li>
      <li parentName="ul">{`The validation of the fields must occur in real time, making it easier for users to complete the task when filling the form.`}</li>
    </ul>
    <h2 {...{
      "id": "text-input-types",
      "style": {
        "position": "relative"
      }
    }}>{`Text Input types`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Text field</th>
      <td>Used when the user needs to insert only one line of text.</td>
    </tr>
    <tr>
      <th>Text area</th>
      <td>Used when the user needs to insert more than a sentence.</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "text-field-1",
      "style": {
        "position": "relative"
      }
    }}>{`Text Field`}</h2>
    <Demo src='pages/components/text-field/TextField' mdxType="Demo" />
    <h2 {...{
      "id": "text-area",
      "style": {
        "position": "relative"
      }
    }}>{`Text Area`}</h2>
    <Demo src='pages/components/text-field/TextArea' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      